import React from 'react';
import { Project, useProjectQuery } from 'schema';
import { Loading } from 'ui/Loading';
import { PropertiesTable, PropertiesTableRow } from 'ui/PropertiesTable';

export const ProjectAmounts = () => {
  const { data, loading } = useProjectQuery();

  if (loading) {
    return <Loading />;
  }

  const { mainAccount, wardsReserveAccount } = data?.project as Project;

  return (
    <PropertiesTable>
      <PropertiesTableRow
        caption="Баланс резерва подопечных"
        value={wardsReserveAccount?.amount}
        link={`/account/${wardsReserveAccount!.id}`}
        isAmount={true}
      />
      <PropertiesTableRow
        caption="Баланс счёта администрации"
        link={`/account/${mainAccount!.id}`}
        value={mainAccount?.amount}
        isAmount={true}
      />
      <PropertiesTableRow
        caption="Баланс депозитов жертвователей"
        value={data!.amounts.amountOfBenefactorDeposits}
        isAmount={true}
      />
    </PropertiesTable>
  );
};
