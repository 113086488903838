import React from 'react';
import { Card, CardActions, CardContent, CardHeader } from '@material-ui/core';
import { ProjectAccounts, ProjectAmounts, AddProjectExpense } from './components';
import { LayoutPageGrid } from 'ui/LayoutGrid/LayoutGrid';
import { InvisibleCard } from 'ui/InvisibleCard';
import { usePermissionPage } from 'hooks';
import { useMeContext } from 'components';

export const ProjectPage = () => {
  const { user } = useMeContext();

  usePermissionPage('/wards', user?.permissions.project.manageExpenses);

  return (
    <>
      <LayoutPageGrid title="Проект">
        <Card>
          <CardHeader title="Показатели проекта" />
          <CardContent>
            <InvisibleCard>
              <CardContent>
                <ProjectAmounts />
              </CardContent>
              <CardActions>
                <AddProjectExpense />
              </CardActions>
            </InvisibleCard>
          </CardContent>
        </Card>
        <Card>
          <CardHeader title="Расходы проекта" />
          <CardContent>
            <ProjectAccounts />
          </CardContent>
        </Card>
      </LayoutPageGrid>
    </>
  );
};
