const invalidFormatMessage = (format: string) => `Неверный формат. Ожидается ${format}.`;

export const formatted = (format: RegExp, expectedFormat: string, required: boolean) => (
  value: any
) => {
  if (value === undefined || value === null || value === '') {
    if (required) {
      return invalidFormatMessage(expectedFormat);
    } else {
      return undefined;
    }
  }

  if (typeof value === 'string' && !format.test(value)) {
    return invalidFormatMessage(expectedFormat);
  }
};

export const passportSeries = formatted(/^\d{4}$/, '1234', false);

export const passportNumber = formatted(/^\d{6}$/, '123456', false);

export const passportIssuerCode = formatted(/^\d{3}-\d{3}$/, '123-456', false);

export const positiveNumber = formatted(/^[0-9]*$/, 'целое положительное число', true);

export const moreThanZero = formatted(/^[1-9]*$/, 'число больше нуля', true);
