import { useCallback } from 'react';
import {
  CreateSpecialProjectInput,
  Document,
  SpecialProjectsDocument,
  useCreateSpecialProjectMutation,
} from 'schema';
import { useCancelRoute } from 'hooks';
import { toast } from 'react-toastify';
import { FORM_ERROR, SubmissionErrors } from 'final-form';

type InitialProps = {
  description: string;
  start: Date | undefined;
  title: string;
  images: Array<Document>;
  requiredAmount?: string;
  end?: Date;
  address?: string;
  city?: string;
  categories: string[];
};

const initialValues: InitialProps = {
  description: '',
  start: undefined,
  title: '',
  images: [],
  categories: [],
};

const useAddSpecialProjectForm = () => {
  const [mutate, { loading, data, error }] = useCreateSpecialProjectMutation({
    errorPolicy: 'all',
  });

  const onSubmit = useCallback(
    async (values: InitialProps) => {
      const input: CreateSpecialProjectInput = {
        ...values,
        categories: values.categories,
        requiredAmount: values.requiredAmount ? parseInt(values.requiredAmount) : null,
        images: values.images.map((image) => image.file.id),
      };
      try {
        return await mutate({
          variables: { input },
          refetchQueries: [
            {
              query: SpecialProjectsDocument,
              variables: { first: 50 },
            },
          ],
        });
      } catch {
        error && toast.error('Ошибка добавления спецпроекта');
        return { [FORM_ERROR]: 'Ошибка добавления' } as SubmissionErrors;
      }
    },
    [mutate, error]
  );

  const { onCancel } = useCancelRoute('/special-projects');

  return {
    initialValues,
    onSubmit,
    onCancel,
    loading,
    data,
    error,
  };
};

export default useAddSpecialProjectForm;
